<template>
  <EuiGridRow>
    <EuiGridColumn>
      <div class="eui-u-mt-16 eui-u-flex eui-u-justify-center">
        <SignatureSuccess/>
      </div>
      <div class="eui-u-flex eui-u-justify-center eui-u-text-2xl eui-u-font-bold eui-u-my-10 eui-u-text-darker-grey">
        {{ $t('paraphers.create.success.message') }}
      </div>
      <div class="eui-u-flex eui-u-justify-center">
        <EuiButton variant="raised" color="primary" @click="$router.push({ name: 'signworkflows', params: { tab: 'sent' } })">
          {{ $t('paraphers.create.success.action') }}
        </EuiButton>
      </div>
    </EuiGridColumn>
  </EuiGridRow>
</template>
<script>
import SignatureSuccess from '../common/SignatureSuccess';

export default {
  name: 'Success',
  components: {
    SignatureSuccess,
  },
};
</script>
